/* eslint-disable no-undef */
// (function (d) {
//   let ele; const fjs = d.getElementsByTagName('body')[0]
//   if (d.getElementById('fb-root')) { return }
//   ele = d.createElement('div')
//   ele.id = 'fb-root'
//   fjs.append(ele)

//   ele = d.createElement('div')
//   ele.id = 'fb-customer-chat'
//   ele.className = 'fb-customerchat'
//   ele.setAttribute('attribution', 'biz_inbox')
//   ele.setAttribute('page_id', '2191951191124338')
//   // ele.setAttribute('attribution', 'setup_tool')
//   fjs.append(ele)
// }(document))

window.fbAsyncInit = function () {
  FB.init({
    cookie: true,
    xfbml: true,
    version: 'v13.0'
  })
  FB.AppEvents.logPageView()
}
