import Vue from 'vue'
import {component as Viewer} from 'v-viewer'
import Loading from '~/components/mlearn/loading'
import NoData from '~/components/mlearn/nodata'
import LockFile from '~/components/mlearn/lock'
import ViewPdf from '~/components/mlearn/document/pdf'
import ViewPdfV2 from '~/components/mlearn/document/pdf-v2'
import BackToTop from '~/components/mlearn/backtotop/index.vue'
import ArrowLeft from '~/components/mlearn/slider/arrow-left.vue'
import ArrowRight from '~/components/mlearn/slider/arrow-right.vue'
import {VueMathjax} from '~/components/mlearn/extra/Mathjax'
import ImageNuxt from '~/components/Image/nuxt'
import ImageCustom from '~/components/Image/index'

const components = {
  NoData,
  Loading,
  ViewPdf,
  ViewPdfV2,
  LockFile,
  Viewer,
  BackToTop,
  VueMathjax,
  ArrowLeft,
  ArrowRight,
  ImageNuxt,
  ImageCustom
}
Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
